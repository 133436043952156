/**

 FEATURE FLAGS
 Documentación:https://www.notion.so/talently/Feature-Flags-996e45712ee244c48b4e3dc4098deab0

**/

/* -------------------- REGULAR -------------------- */
export const REGULAR_DELETE_FIELD_BUSINESS_PROSPECTS = '07_2022_delete_field_business_prospects'
export const SKUS_IMPLEMENTATION = 'squad_03_skus_implementation'
export const SQUAD_04_20241112_PLAN_SELECT_OFFICE_INTERESTEDS_TAB = 'squad_04_20241112_plan_select_office_interesteds_tab'

/* -------------------- MINIS -------------------- */
export const NUEVOS_TAGS = 'mini-nuevos-tags-en-office'
export const SHOW_DRIVERS = 'mini-show-drivers-office'
export const ADD_FIELD_SALARY_EXPECTATION = 'mini-add-field-salary-expectation'
export const MINI_CUOTAS_PORCENTAJES_COLLECTIONS = 'MINI_CUOTAS_PORCENTAJES_COLLECTIONS'
export const MINI_REMOVE_QUOTES = 'MINI_REMOVE_QUOTES'
export const MINI_CHANGE_PRICING_OPTIONS_IN_SALES = 'mini_change_pricing_options_in_sales'
export const MINI_BY_PASS = 'mini-by-pass'
export const MINI_ADD_FIELD_IN_OFFERS = 'mini_add_field_in_offers'
export const MINI_RESET_STATUS = 'MINI_RESET_STATUS'
export const MINI_LAST_DATE_OF_ACTIVITY_OF_THE_CANDIDATE = 'MINI_LAST_DATE_OF_ACTIVITY_OF_THE_CANDIDATE'
export const MINI_HUNTING_MANUAL_CV_LOAD = 'MINI_HUNTING_MANUAL_CV_LOAD'
export const MINI_FILTER_CANDIDATES_BY_HARD_VETTING_ENGLISH = 'MINI_FILTER_CANDIDATES_BY_HARD_VETTING_ENGLISH'
export const MINI_BUTTON_TO_GENERATE_TOKEN = 'MINI_BUTTON_TO_GENERATE_TOKEN'
export const MINI_MAKE_CUSTOMER_COMMENTS_AVAILABLE = 'MINI_MAKE_CUSTOMER_COMMENTS_AVAILABLE'
export const MINI_CREATE_AN_EYE_TO_IMPOSE_COMPANIES = 'MINI_CREATE_AN_EYE_TO_IMPOSE_COMPANIES'
export const MINI_SMART_MESSAGE = 'MINI_SMART_MESSAGE'
export const MINI_ALLOW_EDIT_UTMS_FIELDS = 'MINI_ALLOW_EDIT_UTMS_FIELDS'
export const MINI_FILTER_OF_CANDIDATES_BY_SUBJECT = 'MINI_FILTER_OF_CANDIDATES_BY_SUBJECT'
export const MINI_TOKENS_VALIDATION_ACTIVATION = 'mini_tokens_validation_activation'
export const MINI_IMPROVE_BID_APPROVAL_PROCESS = 'mini_improve_bid_approval_process';

/* -------------------- BUGS -------------------- */
export const BUG_WHATSAPP_CODE = 'BUG-WHATSAPP_CODE'

/* -------------------- TESTAB -------------------- */


/* -------------------- CONFIG -------------------- */

/* -------------------- PROTOTYPES -------------------- */

export const MINI_EDIT_FIELDS_MATCH_USER = 'mini_edit_fields_match_user'


/* -------------------- SQUADS -------------------- */
export const SQUAD_03_20240812_PROMOTIONAL_CODE_FOR_SUBSCRIPTIONS = 'squad_03_20240812_promotional_codes_for_subscriptions'
export const SQUAD_04_20241112_PLAN_SELECT_OFFICE_SLOTS_CREATION = 'squad_04_20241112_plan_select_office_slots_creation'
