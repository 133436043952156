import {
  CONFIG_KANBAN_LITE_FREE,
  CONFIG_KANBAN_PRO,
  CONFIG_KANBAN,
} from '~/utils/constants/offerKanbanStatus'
import { responseStore } from '~/utils/store'
import { MINI_IMPROVE_BID_APPROVAL_PROCESS } from '~/config/featureFlags'

const initialState = {
  kanban: CONFIG_KANBAN.map((item) => {
    return {
      ...item,
      data: [],
      params: {
        type: item.id,
        page: 1,
      },
      loading: true,
      pagination: {
        current_page: 0,
        last_page: 0,
        total: 0,
      },
    }
  }),
  kanbanLiteFree: CONFIG_KANBAN_LITE_FREE.map((item) => {
    return {
      ...item,
      data: [],
      params: {
        type: item.id,
        page: 1,
      },
      loading: true,
      pagination: {
        current_page: 0,
        last_page: 0,
        total: 0,
      },
    }
  }),
  kanbanPro: CONFIG_KANBAN_PRO.map((item) => {
    return {
      ...item,
      data: [],
      params: {
        type: item.id,
        page: 1,
      },
      loading: true,
      pagination: {
        current_page: 0,
        last_page: 0,
        total: 0,
      },
    }
  }),
  historyChanges: [],
  showModalDetails: false,
  detailsModal: {
    columnId: null,
    columnIndex: null,
    elementIndex: null,
    element: null,
  },
}

export const state = () => ({ ...initialState })

export const getters = {
  getLastMove: (state) => {
    if (state.historyChanges.length === 0) return null
    return state.historyChanges[0]
  },
}

export const mutations = {
  SET_STATE_KANBAN(state, { type = 'kanban', value }) {
    state[type] = value
  },
  UPDATE_KEY_COLUMN(state, { type = 'kanban', index, key, value }) {
    state[type][index][key] = value
  },
  SET_ELEMENT_COLUMN_INDEX(state, { index, value, type = 'kanban' }) {
    state[type][index].data = value
  },
  ADD_HISTORY_KANBAN(state, payload) {
    const indexAdd = state.historyChanges.findIndex(
      (item) => item.finalized === false
    )

    if (indexAdd !== -1) {
      state.historyChanges[indexAdd] = {
        ...state.historyChanges[indexAdd],
        ...payload,
      }
      return
    }

    state.historyChanges.unshift(payload)
  },
  ADD_ELEMENT_COLUMN(state, { columnId, element, type = 'kanban' }) {
    const indexAdd = state[type].findIndex((item) => item.id === columnId)

    if (indexAdd !== -1) {
      const indexElement = state[type][indexAdd].data.findIndex(
        (item) => item.id < element.id
      )

      if (indexElement !== -1) {
        state[type][indexAdd].data.splice(indexElement, 0, element)
      } else {
        state[type][indexAdd].data.push(element)
      }

      state[type][indexAdd].pagination.total += 1
    }
  },
  ADD_ELEMENTS_COLUMN(state, { columnId, data, pagination, type = 'kanban' }) {
    const indexAdd = state[type].findIndex((item) => item.id === columnId)

    if (indexAdd !== -1) {
      state[type][indexAdd].data.push(...data)
      state[type][indexAdd].pagination = pagination
    }
  },
  REVERSE_KANBAN_CHANGE(state, { type = 'kanban' }) {
    if (state.historyChanges.length === 0) return
    const { added, removed, element } = state.historyChanges[0]
    state[type][added.columnIndex].data.splice(added.newIndex, 1)
    state[type][removed.columnIndex].data.splice(removed.oldIndex, 0, element)
    state.historyChanges.splice(0, 1)
  },
  REMOVE_ELEMENT_COLUMN(state, { columnId, element, type = 'kanban' }) {
    const indexAdd = state[type].findIndex((item) => item.id === columnId)

    if (indexAdd !== -1) {
      const indexElement = state[type][indexAdd].data.findIndex(
        (item) => item.id === element.id
      )

      if (indexElement !== -1) {
        state[type][indexAdd].data.splice(indexElement, 1)
        state[type][indexAdd].pagination.total -= 1
      }
    }
  },
}

export const actions = {
  async fetchOfferKanban(
    { state, commit, rootState },
    { params, force = false, type = 'kanban' }
  ) {
    if (rootState.everest.offer.offer !== null && force === false) {
      return responseStore(state[type])
    }

    const response =
      await this.$repository.v3.partnership.offersKanban.getOffersKanban(params)
    if (response.success) {
      let formatKanban = response.result.map((item) => {
        const { id, data, name } = item
        const findColumn = state[type].find(
          (itemKanban) => itemKanban.id === item.id
        )

        const newData = {
          data: [],
          pagination: {
            current_page: 0,
            last_page: 0,
            total: 0,
          },
        }

        if (data) {
          newData.loading = false
          newData.data = data.data
          newData.pagination = data.paginate
        }

        return { ...findColumn, ...newData, id, name }
      })

      if (
        type === 'kanban' &&
        this.$hasFeature(MINI_IMPROVE_BID_APPROVAL_PROCESS)
      ) {
        const liteFreeIds = CONFIG_KANBAN_LITE_FREE.map((item) => item.id)
        formatKanban = formatKanban.filter((column) =>
          liteFreeIds.includes(column.id)
        )
      }

      if (type === 'kanbanPro') {
        const proIds = CONFIG_KANBAN_PRO.map((item) => item.id)
        formatKanban = formatKanban.filter((column) =>
          proIds.includes(column.id)
        )
      }

      commit('SET_STATE_KANBAN', { type, value: formatKanban })
    }

    return response
  },
  async fetchColumnKanban({ commit }, { params, type = 'kanban' }) {
    const response =
      await this.$repository.v3.partnership.offersKanban.getOffersKanban(params)

    if (response.success) {
      const [result] = response.result
      commit('ADD_ELEMENTS_COLUMN', {
        columnId: params.type,
        data: result.data.data,
        pagination: result.data.paginate,
        type,
      })
    }

    return response
  },
}
