import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v4/sales/business'

export default () => ({
  postOrder(businessId, body) {
    return useRequestOffice('post', `${rootPath}/${businessId}/orders`, body)
  },
  getBusinessesServices(businessId) {
    return useRequestOffice('get', `${rootPath}/${businessId}/business-service`)
  },
  putBusinessServiceField(businessId, businessServiceId, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/${businessId}/business-service/${businessServiceId}/update-field`,
      body
    )
  },
  putBusinessService(businessId, businessServiceId, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/${businessId}/business-service/${businessServiceId}`,
      body
    )
  },
  deleteBusinessService(businessId, businessServiceId) {
    return useRequestOffice(
      'delete',
      `${rootPath}/${businessId}/business-service/${businessServiceId}`
    )
  },
  postCancelSubscription(businessId, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${businessId}/cancel-subscription`,
      body
    )
  },
  getBusinessOfferSlots(businessId, params) {
    return useRequestOffice('get', `${rootPath}/${businessId}/orders/slots`, {
      params,
    })
  },
  getAvailableWorkOffersForSlots(businessId) {
    return useRequestOffice(
      'get',
      `${rootPath}/${businessId}/work-offers/available-for-slots`
    )
  },
  assignToSlot(businessId, workOfferId, params) {
    return useRequestOffice(
      'post',
      `${rootPath}/${businessId}/work-offers/${workOfferId}/assign-to-slot`,
      params
    )
  },
  releaseSlot(businessId, workOfferId, slotId) {
    return useRequestOffice(
      'post',
      `${rootPath}/${businessId}/work-offers/${workOfferId}/release-slot/${slotId}`
    )
  },
  transition(businessId, businessServiceId, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${businessId}/business-service/${businessServiceId}/transition/`,
      body
    )
  },
})
